<template>
  <div>
    <div class="d-flex flex-column flex-root" style="height: 100vh">
      <div class="
          login login-1
          d-flex
          flex-column flex-lg-row flex-column-fluid
          bg-white
        " :class="{
          'login-signin-on': this.state == 'login',
          'login-signup-on': this.state == 'cadastro',
          'login-forgot-on': this.state == 'forgot',
        }" id="kt_login">
        <!--begin::Aside-->
        <div class="
            login-aside
            d-flex
            flex-column flex-row-auto
            justify-content-center
          " v-show="!tirar_header">
          <div class="d-flex flex-column-auto flex-column logo-mobile">
            <a href="#" class="text-center">
              <img src="/media/logo.png" class="max-h-140px w-50" alt="" />
            </a>
          </div>
        </div>
        <!--begin::Aside-->
        <!--begin::Content-->
        <div class="
            login-content
            flex-row-fluid
            d-flex
            flex-column
            justify-content-center
            position-relative
            p-7
            bg-secondary
          ">
          <div class="d-flex flex-column-fluid flex-center">
            <!--begin::Signin-->
            <div class="login-form login-signin">
              <form class="form" novalidate="novalidate" id="kt_login_form" @submit.prevent="">
                <div class="pb-13 pt-lg-0 pt-0">
                  <h3 class="
                      font-weight-black
                      text-white
                      text-center
                      font-title-home
                      text-primary m-0">
                    INTERNET TEM QUE SER NICNET!
                  </h3>

                  <div class="text-white text-center pt-5 font-size-h5 font-size-h4-lg">
                    Preencha seus dados e solicite<br>orçamento para seu evento.
                  </div>
                </div>

                <!-- Form de preenchimento de cpf -->
                <div class="form-group" v-show="!campoCredenciais">
                  <label class="font-size-h6 font-weight-bolder text-white">Digite seu CPF ou CNPJ</label>
                  <div>
                    <TheMask :mask="['###.###.###-##', '##.###.###/####-##']" name="documento" autocomplete="off"
                      v-model="form.documento" :class="{
                          'is-invalid': submitted && $v.form.documento.$error,
                        }" class="
                        form-control form-control-solid
                        h-auto
                        py-4
                        px-6
                      "></TheMask>

                    <div v-if="submitted && $v.form.documento.$error" class="invalid-feedback">
                      Preencha o campo corretamente
                    </div>
                  </div>
                </div>
                <div class="form-group" v-show="!campoCredenciais">
                  <div class="pb-lg-0 pb-5 text-center">
                    <button type="button" ref="verificaCPFBtn" class="
                        btn btn-light
                        text-dark
                        font-weight-bolder
                        px-15
                        py-4
                        my-3
                        font-size-lg
                        mr-3
                        btn-verificarCPF
                      " @click="verificaCPF()">
                      Continuar
                    </button>
                  </div>
                </div>
                <!-- Final form de preenchimento de cpf -->
              </form>
            </div>

            <!--end::Signin-->
            <!--begin::Signup-->
            <div class="login-form login-signup">
              <form class="form" novalidate="novalidate" id="kt_cadastro_form" @submit.prevent="">
                <div class="pb-10 pt-lg-0 pt-5">
                  <p class="text-white font-weight-bold font-size-h4">
                    <span v-if="form.documento.length < 14">Agora preciso saber o seu nome completo e o seu telefone.</span>
                    <span v-else>Agora preciso saber o nome e o telefone.</span>
                    
                  </p>
                </div>
                <p class="text-white font-weight-bold font-size-h4">
                  Documento: {{ mascaraDocumento(this.form.documento) }}
                </p>
                <div class="mb-6"></div>
                <div class="form-group">
                  <input class="
                      form-control
                      h-auto
                      py-7
                      px-6
                      font-size-h6
                    " type="text" placeholder="Nome" name="nome" ref="nome" v-model="form.nome" :class="{
                        'is-invalid': submitted && $v.form.nome.$error,
                      }" autocomplete="off" />
                  <!-- @blur="salvarInformacoes('nome', form.nome)" -->
                  <div v-if="submitted && $v.form.nome.$error" class="invalid-feedback text-white">
                    Preencha o campo corretamente
                  </div>
                </div>

                <div class="form-group">
                  <TheMask :mask="['(##) #####-####']" name="telefone" autocomplete="off" placeholder="Telefone"
                    v-model="form.telefone" :class="{
                        'is-invalid': submitted && $v.form.telefone.$error,
                      }" class="
                        form-control
                        h-auto
                        py-7
                        px-6
                        font-size-h6
                      "></TheMask>

                  <!-- @blur="salvarInformacoes('telefone', form.telefone)" -->
                  <div v-if="submitted && $v.form.telefone.$error" class="invalid-feedback text-white">
                    Preencha o campo corretamente
                  </div>
                </div>
                <div class="form-group">
                  <input class="
                      form-control
                      h-auto
                      py-7
                      px-6
                      font-size-h6
                    " type="text" placeholder="E-mail" name="email1" ref="email1" :disabled="form.sem_email"
                    v-model="form.email1" :class="{
                        'is-invalid': submitted && $v.form.email1.$error,
                      }" autocomplete="off" />
                  <!-- @blur="salvarInformacoes('nome', form.nome)" -->
                  <div v-if="submitted && $v.form.email1.$error" class="invalid-feedback text-white">
                    Preencha o campo corretamente
                  </div>
                </div>

                <!-- <div class="col-12 align-self-center mb-4">
                  <label class="checkbox text-white font-size-lg lead">
                    <input type="checkbox" v-on:click="disableInputEmail" />
                    <span class="mr-2"></span> Não tenho email
                  </label>
                </div> -->

                <div class="
                    form-group
                    d-flex
                    flex-wrap
                    pb-lg-0 pb-3
                    justify-content-center
                  ">
                  <button type="button" id="kt_login_signup_cancel" class="
                      btn btn-primary
                      font-weight-bolder font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-4
                    " @click="showForm('login', 'kt_login_form')">
                    Voltar
                  </button>
                  <button ref="btnConcluirCadastro" class="
                      btn btn-light
                      text-dark
                      font-weight-bolder font-size-h6
                      px-8
                      py-4
                      my-3
                    " style="width: 150px" @click="concluirCadastro()">
                    Cadastrar
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!--end::Content footer-->
        </div>
        <!--end::Content-->
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { cpf, cnpj } from "cpf-cnpj-validator";
import { required, minLength } from "vuelidate/lib/validators";
import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState, mapActions } from "vuex";
import {
  LOGIN,
  LOGAR,
  LOGOUT,
  CADASTRAR,
} from "@/core/services/store/auth/store/constant";
import Swal from "sweetalert2";
import {
  retirarmascaraDocumentoCNPJ,
  retiraMascaraTelefone,
  loadingButton,
  mascaraDocumento,
  dataHoraHoje, IsEmailValido,
} from "@/core/services/functionsGlobal";
import { TheMask } from "vue-the-mask";

export default {
  name: "login",
  data() {
    return {
      state: "login",
      form: {
        documento: "",
        telefone: "",
        nome: "",
        email1: "",
        id: "",
        sem_email: false,
      },
      campoCredenciais: false,
      submitted: false,
      cpfOrCnpj: 0, // 0 é cpf e 1 cnpj
      tirar_header: false,
    };
  },
  components: {
    TheMask,
  },
  validations: {
    form: {
      documento: {
        required,
        minLength: minLength(11),
      },
      nome: {
        required,
        validaNome: (nome) => {
          if (
            typeof nome == "undefined" ||
            nome.trim().split(" ").length <= 1
          ) {
            return false;
          } else {
            return true;
          }
        },
      },
      telefone: {
        required,
        validaTelefone: (telefone) => {
          if (retiraMascaraTelefone(telefone).length == 11) {
            return true;
          } else {
            return false;
          }
        },
      },
      email1: {
        validaEmail: (email1, params) => IsEmailValido(email1, params),
      },
    },
  },
  methods: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["auth/currentUser"]),
    ...mapActions("auth", [LOGAR, LOGOUT, LOGIN, CADASTRAR]),

    showForm(state, nomeForm) {
      this.state = state;
      KTUtil.animateClass(
        KTUtil.getById(nomeForm),
        "animate__animated animate__backInUp"
      );
    },
    mascaraDocumento,
    dataHoraHoje,
    async verificaCPF() {
      // seta como submitted
      this.submitted = true;

      //sinaliza para os childs que foi feito o submit do form
      this.$v.form.documento.$touch();

      // valida se o campo é valido
      if (this.$v.form.documento.$invalid) {
        return;
      }

      // valida se o documento digitado é valido
      if (
        cpf.isValid(this.form.documento) ||
        cnpj.isValid(this.form.documento)
      ) {
        // reseta o submitted
        this.submitted = false;
        loadingButton(this.$refs["verificaCPFBtn"], true, "primary");

        await this[LOGIN]({
          documento: retirarmascaraDocumentoCNPJ(this.form.documento),
        })
          .then((dados) => {
            this[LOGAR](dados);

            let url = `/home`;

            if(this.$route.params.slug){
              url = `/${this.$route.params.slug}/home`;
            }

            this.$router.push({ path: url, query: this.$route.query});
            loadingButton(this.$refs["verificaCPFBtn"], false);
          })
          .catch((error) => {
            if (error.response) {
              // caso o server responda diferente de 200
              if (error.response.status == 400) {
                // erro de usuario não encontrado
                this.showForm("cadastro", "kt_cadastro_form");
              }
            } else if (error.request) {
              // Caso seja um erro que o navegador retornou (nem chegou no server)
              console.log(error.request);
            }

            loadingButton(this.$refs["verificaCPFBtn"], false);
          });
      } else {
        Swal.fire({
          title: "Dados incorretos!",
          text: "O documento digitado é inválido!",
          icon: "error",
          heightAuto: false,
        });
      }
    },
    async salvarInformacoes(campo, parametro) {
      if (campo == "telefone") {
        // seta como submitted
        this.submitted = true;

        this.$v.form.telefone.$touch();
        // valida se o campo é valido
        if (this.$v.form.telefone.$invalid) {
          return;
        }

        this.submitted = false;
        parametro = retiraMascaraTelefone(parametro);
      } else {
        // seta como submitted
        this.submitted = true;

        this.$v.form.nome.$touch();
        // valida se o campo é valido
        if (this.$v.form.nome.$invalid) {
          return;
        }
      }

      await this[CADASTRAR](
        {
          [campo]: parametro,
          id: this.form.id,
          documento: retirarmascaraDocumentoCNPJ(this.form.documento),
        }
      );
    },
    async concluirCadastro() {

      if (!this.form.sem_email) {
        IsEmailValido(this.form.email1, true);
      }

      // seta como submitted
      this.submitted = true;


      this.$v.form.telefone.$touch();
      this.$v.form.documento.$touch();
      this.$v.form.nome.$touch();
      this.$v.form.email1.$touch();
      // valida se o campo é valido
      if (
        this.$v.form.telefone.$invalid ||
        this.$v.form.documento.$invalid ||
        this.$v.form.nome.$invalid
      ) {
        return;
      }

      if (!this.form.sem_email) {
        if (this.$v.form.email1.$invalid) return;
      }

      loadingButton(this.$refs["btnConcluirCadastro"], true, "primary");

      await this[CADASTRAR]({
        cpf_cnpj: this.form.documento,
        email1: this.form.email1,
        telefone1: retiraMascaraTelefone(this.form.telefone),
        razao_social: this.form.nome,
      })
        .then(async () => {
          // window.dataLayer = window.dataLayer || [];
          // window.dataLayer.push({
          // event: "cadastro_efetuado",
          // });

          await this.verificaCPF();
          // desabilita o loading do botao de cadastrar
          loadingButton(this.$refs["btnConcluirCadastro"], false);

        })
        .catch(() => {
          loadingButton(this.$refs["btnConcluirCadastro"], false);
          this.$vToastify.warning(
            "Ocorreu um erro ao cadastrar! Aguarde uns segundos e tente novamente.",
            "Atenção"
          );
        });
    },
    voltarEscolhaCPF() {
      this.campoCredenciais = false;
    },
    disableInputEmail() {
      // recebe o inverso do que está
      this.form.email1 = "";
      this.form.sem_email = !this.form.sem_email;
      this.submitted = false;
      this.$v.$touch();
      this.$forceUpdate();
    },
  },
  created() {
    if (typeof this.$route.query.app_hash !== "undefined") {
      this.tirar_header = true;
    }


  },
};
</script>
